import React, { useState, useContext, useEffect } from 'react';

import ReusableButton from '@components/reusable-buttons/blog-button';
import CloseIcon from '@assets/browse-books/icon-close.svg';
import CloseNotification from '@assets/svg/close-notification.svg';
import Tick from '@assets/svg/tick-mark.svg';
import BackDrop from '../backdrop/backdrop';
import GotItButton from '@components/reusable-buttons/secondary-button';
import LanguageContext from '../../context/language-context';
import { REQUEST_FORM_API } from '../../common/api';
import CartContext from '../../context/cart-context';

export default function RequestForm({ showFunction, showStatus, enableScroll }) {
  const { requestFormBook } = useContext(CartContext);

  const [requestFormInput_errorClass, setrequestFormInput_errorClass] = useState({
    errorClassName: 'request-form-input',
    errorClassEmail: 'request-form-input',
  });
  const [errorMessage, setErrorMessage] = useState({
    errorMessageName: '',
    errorMessageEmail: '',
  });

  const [showNotification, setShowNotification] = useState(false);

  const splitFirstName = (fullName) => {
    const name = fullName.trim();
    const temp = name.split(' ');
    const fName = temp[0];
    return fName;
  };

  const splitLastName = (fullName) => {
    const name = fullName.trim();
    const temp = name.split(' ');
    const lName = temp[temp.length - 1];
    return lName;
  };
  const [requestFormData, setRequestFormData] = useState({
    fullName: '',
    email: '',
    phone: '',
    state: '',
    zip: '',
    address: '',
    city: '',
    apartment: '',
    bookToBuy: requestFormBook,
  });

  const formData = {
    fname: splitFirstName(requestFormData.fullName),
    lname: splitLastName(requestFormData.fullName),
    phone: requestFormData.phone,
    email: requestFormData.email,
    address: requestFormData.address,
    aptno: requestFormData.apartment,
    city: requestFormData.city,
    state: requestFormData.state,
    zip: requestFormData.zip,
    book: requestFormData.bookToBuy,
    from: 'BETTERWAYOFLIFE',
  };

  const { contentLanguage } = useContext(LanguageContext);

  const contentResources = {
    eng: {
      titleFirstHalf: 'Request for more ',
      titleSecondHalf: 'information',
      formSuggestion:
        'Please enter your basic destails so that our sales representative can reach you for the books that you are interested in.',
      name: 'Name',
      phone: 'Phone Number',
      email: 'Email',
      address: 'Address',
      state: 'State',
      zip: 'Zip',
      interestedBook: 'Book of Interest',
      city: 'City',
      apartment: 'Unit/Apartment Number(Optional)',
      offerText: 'Its okay to send the latest offers in my email',
      agreement1: 'By clicking send, you agree to our',
      privacyPolicy: 'Privacy policy',
      privacyPolicy2: 'Privacy policy and',
      termsCondtions: 'Terms & Conditions',
      exploreBlogButton: 'Explore Blogs',
      sendRequst: 'Send Request',
      yourFullName: 'Your Full Name',
      yourEmail: 'Your Email Address',
      yourPhoneNumber: 'Your Phone Number',
      localAddress: 'Local Address',
      apartmentNo: 'Apartment Number',
      cityName: 'City Name',
      yourState: 'Your State',
      zipCode: 'Zip Code',
      bookYouWantToBuy: 'Book you want to buy',
    },
    esp: {
      titleFirstHalf: 'Solicitud de más ',
      titleSecondHalf: 'información',
      formSuggestion:
        'Ingrese sus datos básicos para que nuestro representante de ventas pueda comunicarse con usted para obtener los libros que le interesan.',
      name: 'Nombre',
      phone: 'Teléfono',
      email: 'Email',
      zip: 'Código Postal',
      address: 'Dirección',
      interestedBook: 'Libro de interés',
      city: 'Ciudad',
      apartment: 'Número  (opcional)',
      state: 'Estado',
      offerText: 'Está bien enviar las últimas ofertas en mi correo electrónico',
      agreement1: 'Al hacer clic en enviar, aceptas nuestra',
      privacyPolicy: 'Política de privacidad policy',
      privacyPolicy2: 'Política de privacidad y',
      termsCondtions: 'Términos y condiciones',
      exploreBlogButton: 'Explorar Blogs',
      sendRequst: 'Enviar Petición',
      yourFullName: 'Tu nombre completo',
      yourEmail: 'Tu correo electrónico',
      yourPhoneNumber: 'Su  teléfono',
      localAddress: 'Dirección local',
      apartmentNo: 'Número ',
      cityName: 'Nombre de la ciudad',
      yourState: 'Tu estado',
      zipCode: 'Código postal',
      bookYouWantToBuy: 'Libro que quieres comprar',
    },
  };
  const [currentContent, setCurrentContent] = useState(contentResources.eng);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setRequestFormData((prevState) => ({
      ...prevState,
      bookToBuy: requestFormBook,
    }));
  }, [requestFormBook]);

  useEffect(() => {
    if (contentLanguage === 'esp') {
      setCurrentContent(contentResources.esp);
    } else {
      setCurrentContent(contentResources.eng);
    }
  }, [contentLanguage]);

  const sendRequest = async () => {
    if (requestFormData.fullName === '' || requestFormData.email === '') {
      return;
    } else {
      setIsLoading(true);
      const response = await fetch(REQUEST_FORM_API, {
        method: 'POST',

        headers: {
          'Content-Type': 'application/json',
        },

        body: JSON.stringify(formData),
      });
      if (response.status == '200') {
        setIsLoading(false);
        setShowNotification(!showNotification);
      }

      console.error('request sent success', response);
      setIsLoading(false);
    }
  };

  const handleChange = (event) => {
    const input = event.target;

    setRequestFormData((prevState) => {
      return {
        ...prevState,
        [input.name]: input.value,
      };
    });
  };

  const validateForm = () => {
    switch (requestFormData.fullName.length) {
      case 0: {
        setrequestFormInput_errorClass((prevState) => ({
          ...prevState,
          errorClassName: 'request-form-input error',
        }));
        setErrorMessage((prevState) => ({
          ...prevState,
          errorMessageName: 'Name field is required.',
        }));
        break;
      }

      default: {
        setrequestFormInput_errorClass((prevState) => ({
          ...prevState,
          errorClassName: 'request-form-input',
        }));
        setErrorMessage((prevState) => ({
          ...prevState,
          errorMessageName: '',
        }));
      }
    }

    switch (requestFormData.email.length) {
      case 0:
        setrequestFormInput_errorClass((prevState) => ({
          ...prevState,
          errorClassEmail: 'request-form-input error',
        }));
        setErrorMessage((prevState) => ({
          ...prevState,
          errorMessageEmail: 'Email field is required.',
        }));

        break;
      default: {
        setrequestFormInput_errorClass((prevState) => ({
          ...prevState,
          errorClassEmail: 'request-form-input',
        }));
        setErrorMessage((prevState) => ({
          ...prevState,
          errorMessageEmail: '',
        }));
      }
    }
  };
  return (
    <div id="top" className="request-form-outermost-container">
      <div className="request-form-container common-container u-margin-top-md u-margin-bottom-lg ">
        <div
          className="request-form-close-icon"
          onKeyPress={() => null}
          role="button"
          tabIndex={-1}
          onClick={() => {
            showFunction(!showStatus);
            enableScroll();
          }}
        >
          <img src={CloseIcon} alt="cross-icon" draggable="false" />
        </div>

        <div className="request-form-title-container">
          <h2>{currentContent?.titleFirstHalf}</h2>
          <h2 className="h2-modified">{currentContent?.titleSecondHalf}</h2>
        </div>

        <p className="request-form-suggestions">{currentContent?.formSuggestion}</p>

        <form className="request-form-body" onSubmit={() => {}}>
          <span className="request-form-input-container">
            <label htmlFor="name">{currentContent?.name}*</label>

            <input
              className={requestFormInput_errorClass.errorClassName}
              required
              id="name"
              name="fullName"
              type="text"
              placeholder={currentContent?.yourFullName}
              onChange={handleChange}
              value={requestFormData.fullName}
            />
            <p className="form-error-message">{errorMessage.errorMessageName}</p>
          </span>

          <span className="request-form-input-container">
            <label htmlFor="email">{currentContent?.email}*</label>

            <input
              className={requestFormInput_errorClass.errorClassEmail}
              id="email"
              required
              type="email"
              name="email"
              onChange={handleChange}
              value={requestFormData.email}
              placeholder={currentContent?.yourEmail}
            />
            <p className="form-error-message">{errorMessage.errorMessageEmail}</p>
          </span>
          <span className="request-form-input-container">
            <label htmlFor="phone"> {currentContent?.phone}</label>

            <input
              className="request-form-input"
              id="phone"
              name="phone"
              type="tel"
              placeholder={currentContent?.yourPhoneNumber}
              onChange={handleChange}
              value={requestFormData.phone}
            />
          </span>
          <span className="request-form-input-container">
            <label htmlFor="address">{currentContent?.address}</label>

            <input
              className="request-form-input"
              id="address"
              type="text"
              name="address"
              placeholder={currentContent?.localAddress}
              onChange={handleChange}
              value={requestFormData.address}
            />
          </span>
          <span className="request-form-input-container">
            <label htmlFor="apartment">{currentContent?.apartment}</label>

            <input
              className="request-form-input"
              id="apartment"
              type="number"
              name="apartment"
              placeholder={currentContent?.apartmentNo}
              onChange={handleChange}
              value={requestFormData.apartment}
            />
          </span>
          <span className="request-form-input-container">
            <label htmlFor="city">{currentContent?.city}</label>

            <input
              className="request-form-input"
              id="city"
              type="text"
              name="city"
              placeholder={currentContent?.cityName}
              onChange={handleChange}
              value={requestFormData.city}
            />
          </span>
          <span className="request-form-input-container">
            <label htmlFor="state">{currentContent?.state}</label>

            <input
              className="request-form-input"
              id="state"
              name="state"
              type="text"
              placeholder={currentContent?.yourState}
              onChange={handleChange}
              value={requestFormData.state}
            />
          </span>
          <span className="request-form-input-container">
            <label htmlFor="zip">{currentContent?.zip}</label>

            <input
              className="request-form-input"
              id="zip"
              name="zip"
              type="number"
              placeholder={currentContent?.yourState}
              required
              pattern="\d{5,5}(-\d{4,4})?"
              onChange={handleChange}
              value={requestFormData.zip}
            />
          </span>
          <span className="request-form-input-container">
            <label htmlFor="zip">{currentContent?.interestedBook}</label>

            <input
              className="request-form-input"
              id="bookToBuy"
              name="bookToBuy"
              type="text"
              placeholder={currentContent?.bookYouWantToBuy}
              required
              pattern="\d{5,5}(-\d{4,4})?"
              onChange={handleChange}
              value={requestFormData.bookToBuy}
              disabled
            />
          </span>
        </form>

        <div className="request-form-checkbox">
          <input className="request-checkbox" type="checkbox" />
          <p>{currentContent?.offerText}</p>
        </div>
        <p className="request-form-agreement-text">
          {currentContent?.agreement1}
          <span className="p-modified ">{currentContent?.privacyPolicy} </span>
          {currentContent?.privacyPolicy2}
          <p className="p-modified">{currentContent?.termsCondtions}</p>
        </p>
        <div
          className="send-request-button-container"
          onKeyPress={() => null}
          role="button"
          tabIndex={0}
          onClick={() => {
            validateForm();
            sendRequest();
          }}
        >
          <ReusableButton buttonText={currentContent?.sendRequst} isLoading={isLoading} />
        </div>
      </div>
      <div className={showNotification ? 'form-submission-notification' : 'hide'}>
        <div className="close-icon-notification">
          <img
            className="cursor-pointer"
            onClick={() => setShowNotification(!showNotification)}
            src={CloseNotification}
          />
        </div>
        <div className="tick-mark-notification">
          <img src={Tick} />
        </div>
        <h4>Thank you for submitting your request for more information.</h4>
        <h6>Someone will be in contact with you shortly</h6>
        {/* <div className="notification-button-holder" onClick={() => setShowNotification(!showNotification)}>
          <GotItButton buttonText="Got It" />
        </div> */}
      </div>
      <div
        className={showNotification ? 'show' : 'hide'}
        onClick={() => {
          setShowNotification(!showNotification);
        }}
      >
        <BackDrop />
      </div>
    </div>
  );
}
