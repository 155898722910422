import React, { useEffect, useContext, useState } from 'react';
import { Link } from 'gatsby';

import LanguageContext from '../../context/language-context';
import LeftArrowContact from '@assets/contact/left-arrow';

export default function BrowseHero() {
  const { contentLanguage } = useContext(LanguageContext);

  const contentResources = {
    eng: {
      titleFirstHalf: 'Find the books ',
      titleSecondHalf: 'you love..',
      home: 'Home',
      books: 'Books',
      heroDesc1: `Reading health-related books may be difficult for most of us. `,
      heroDesc2:
        'However, we have some interesting books in our library that will help you gain life-changing insights into health and well-being. Check out our healthy living bookstore and find the books of your choice.',
      exploreBlogButton: 'Explore Blogs',
    },
    esp: {
      titleFirstHalf: 'Encuentra los libros ',
      titleSecondHalf: 'que amas..',
      home: 'Hogar',
      books: 'Libros',
      heroDesc1: `Leer libros relacionados con la salud puede ser difícil para la mayoría de nosotros.`,
      heroDesc2:
        ' Sin embargo, tenemos algunos libros interesantes en nuestra biblioteca que lo ayudarán a obtener conocimientos sobre la salud y el bienestar que le cambiarán la vida. Visite nuestra librería de vida saludable y encuentre los libros de su elección.',
      exploreBlogButton: 'Explorar Blogs',
    },
  };

  useEffect(() => {
    if (contentLanguage === 'esp') {
      setCurrentContent(contentResources.esp);
    } else {
      setCurrentContent(contentResources.eng);
    }
  }, [contentLanguage]);
  const [currentContent, setCurrentContent] = useState(contentResources.eng);

  return (
    <div className="browse-books-background-color-container">
      <div className="browse-hero-outermost">
        <div className="browse-books-hero u-margin-top-lg common-container">
          <div className="browse-holder">
            <ul class="privacy-policy-breadcrumb">
              <Link to="/">
                <li className="opacity-low ">{currentContent?.home}</li>
              </Link>
              <LeftArrowContact />
              <li>{currentContent?.books}</li>
            </ul>

            <h1>{currentContent?.titleFirstHalf} </h1>
            <h1 className="h1-modified">{currentContent?.titleSecondHalf} </h1>
            <p className="browse-books-text-p">
              {currentContent.heroDesc1}
              <p className="browse-books-text-p">{currentContent.heroDesc2}</p>
            </p>
          </div>
        </div>
        <div className="browse-books-background"></div>
      </div>
    </div>
  );
}
