import React from 'react';

import Layout from '@layouts';
import Seo from '@layouts/common/seo';
import seoImage from '@assets/blog/images/book.png';
import BrowseHero from '@components/book/browse-hero';
import BooksComponent from '@components/book/books-component';

export default function AllBooks() {
  return (
    <>
      <Seo
        title="Best Books for Healthy Living | Better Way of Life"
        description="Discover the Healthy Living Books Written by Medical Experts That Will Change Your Way of Living.Get the Books to Lead The Healthy Lifestyle Now!!"
        seoImage={seoImage}
        siteUrl={process.env.CLIENT_URL + 'books'}
      />
      <Layout>
        <BrowseHero />
        <BooksComponent />
      </Layout>
    </>
  );
}
