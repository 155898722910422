import React from 'react';
import ShoppingBagButton from '@assets/button/shopping-bag';

const AddToCarts = ({ buttonText }) => {
  return (
    <div className="add-to-carts-container">
      <ShoppingBagButton />
      <span className="add-to-cart-text">{buttonText}</span>
    </div>
  );
};

export default AddToCarts;
