import React from 'react';

export default function ShoppingBagButton() {
  return (
    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6.97656 2L3.97656 6V20C3.97656 20.5304 4.18728 21.0391 4.56235 21.4142C4.93742 21.7893 5.44613 22 5.97656 22H19.9766C20.507 22 21.0157 21.7893 21.3908 21.4142C21.7658 21.0391 21.9766 20.5304 21.9766 20V6L18.9766 2H6.97656Z"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M3.97656 6H21.9766" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M16.9766 10C16.9766 11.0609 16.5551 12.0783 15.805 12.8284C15.0548 13.5786 14.0374 14 12.9766 14C11.9157 14 10.8983 13.5786 10.1481 12.8284C9.39799 12.0783 8.97656 11.0609 8.97656 10"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
