import React, { useContext, useState, useEffect } from 'react';
import { Link } from 'gatsby';

import AddToCarts from '@components/blog-details/buttons/addToCarts';
import BlogButton from '@components/reusable-buttons/blog-button';
import CartContext from '../../context/cart-context';
import LanguageContext from '../../context/language-context';

const BookCard = (props) => {
  const book = props?.book;
  const shopifyId = props?.book?.shopifyId;
  const [selectedVariant, setSelectedVariant] = useState(null);

  const { geolocationStatus, updateLineItem, getProductById, requestFormBook, setRequestFormBook } =
    useContext(CartContext);

  function avoidScroll() {
    var x = window.scrollX;
    var y = window.scrollY;
    window.onscroll = function () {
      window.scrollTo(x, y);
    };
  }

  useEffect(() => {
    getProductById(shopifyId).then((result) => {
      setSelectedVariant(result.variants[0]);
    });
  }, [shopifyId]);

  const { contentLanguage } = useContext(LanguageContext);

  const contentResources = {
    eng: {
      requestTheBook: 'Request The Book',

      viewDetails: 'View Details',
      addToCart: 'Add To Cart',
    },
    esp: {
      requestTheBook: 'Solicitar el libro',

      viewDetails: 'Ver detalles',
      addToCart: 'Agregar al Carrito',
    },
  };

  useEffect(() => {
    if (contentLanguage === 'esp') {
      setCurrentContent(contentResources.esp);
    } else {
      setCurrentContent(contentResources.eng);
    }
  }, [contentLanguage]);
  const [currentContent, setCurrentContent] = useState(contentResources.eng);

  const handleAddToCart = () => {
    updateLineItem({ variantId: selectedVariant.id, quantity: 1 });
  };

  return (
    <div className=" browse-books-items-holder" key={book.handle}>
      <div className="books-description-container">
        <div className="books-browse-image">
          <Link to={book.handle}>
            <img src={book.images[0].src} alt="browse" />
          </Link>
        </div>

        <div className="books-description-text">
          <div style={{ height: '18rem' }}>
            <Link to={book.handle}>
              <h5>{book?.title}</h5>
            </Link>
            <p>{book.description}</p>
          </div>
          {geolocationStatus ? (
            <div className="books-add-to-cart-container" onClick={() => handleAddToCart()}>
              <AddToCarts buttonText={currentContent?.addToCart} />
            </div>
          ) : (
            <div
              role="button"
              onKeyPress={() => null}
              tabIndex={-2}
              onClick={() => {
                props.setRequestForm(true);
                setRequestFormBook(book.title);
                avoidScroll();
              }}
              className="button-holder"
            >
              <BlogButton buttonText={currentContent?.requestTheBook} />
            </div>
          )}

          <Link to={book.handle}>
            <div className="browse-books-view">{currentContent?.viewDetails}</div>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default BookCard;
