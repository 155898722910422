import { graphql, useStaticQuery } from 'gatsby';
import React, { useState, useEffect, useContext } from 'react';

import SecondaryButton from '@components/reusable-buttons/secondary-button';
import RequestForm from './request-form-outside';
import LanguageContext from '../../context/language-context';
import BookCard from './book-card';

export default function BooksComponent() {
  const allBooks = useStaticQuery(graphql`
    query {
      allPrismicBooks {
        nodes {
          data {
            cover_image {
              fixed {
                src
              }
            }
            title {
              text
            }
            book_info {
              text
            }
          }
          uid
        }
      }
      allShopifyProduct(filter: { tags: { in: "bwol" } }) {
        nodes {
          tags
          handle
          title
          shopifyId
          metafields {
            value
          }

          description
          id
          images {
            src
          }
          priceRangeV2 {
            maxVariantPrice {
              amount
            }
          }
        }
      }
    }
  `);

  const { contentLanguage } = useContext(LanguageContext);
  const books = allBooks.allShopifyProduct.nodes.sort((a, b) => {
    return a.metafields[0]?.value - b.metafields[0]?.value;
  });

  //To be changed in future
  //const engBooks = books.allShopifyProduct.nodes.filter((item) => item.tags.find((item) => item === 'eng'));
  const engBooks = books.filter((item) => item.tags.find((item) => item === 'eng'));
  //const espBooks = allBooks.allShopifyProduct.nodes.filter((item) => item.tags.find((item) => item === 'esp'));
  const espBooks = books.filter((item) => item.tags.find((item) => item === 'esp'));
  const [nodes, setNodes] = useState([]);
  const contentResouces = {
    eng: {
      titleFirstHalf: 'ALL',
      titleSecondHalf: 'Books',
      requestBook: 'Request The Book',
      loadMore: 'Load More',
      showLess: 'Show Less',
    },
    esp: {
      titleFirstHalf: 'Todos',
      titleSecondHalf: 'Libros',
      requestBook: 'Solicita el libro',
      loadMore: 'Carga Más',
      showLess: 'Muestra Menos',
    },
  };

  const [currentContent, setCurrentContent] = useState({});

  function enableScroll() {
    window.onscroll = function () {};
  }

  useEffect(() => {
    if (contentLanguage === 'eng') {
      setNodes(engBooks);
      setBooksToLoad(engBooks.filter((item, idx) => idx < 6));
      setFilteredItemsToShow(engBooks.filter((item, idx) => idx < 6));
      setCurrentContent(contentResouces.eng);
    } else {
      setCurrentContent(contentResouces.esp);
      setBooksToLoad(espBooks.filter((item, idx) => idx < 6));
      setNodes(espBooks);
      setFilteredItemsToShow(espBooks.filter((item, idx) => idx < 6));
    }
  }, [contentLanguage]);

  const [filteredBooksToShow, setFilteredItemsToShow] = useState([]);

  const [showRequestForm, setRequestForm] = useState(false);

  const [booksToLoad, setBooksToLoad] = useState([]);

  const [showLoadMore, setShowLoadMore] = useState(true);

  return (
    <div className="browse-books-items-outer common-container u-margin-top-md ">
      <div className="browse-books-heading">
        <div className="browse-books-heading-text">
          <h2>{currentContent?.titleFirstHalf}</h2>
          <h2 className="h2-modified">{currentContent?.titleSecondHalf}</h2>
        </div>
      </div>
      {booksToLoad.map((book) => (
        <BookCard book={book} setRequestForm={setRequestForm} />
      ))}
      <div className="load-button-holder-browsebooks ">
        <div
          className="load-more-books"
          className={showLoadMore ? 'show' : 'hide'}
          onClick={() => {
            setBooksToLoad(nodes);
            setShowLoadMore(!showLoadMore);
          }}
        >
          <SecondaryButton buttonText={currentContent?.loadMore} />
        </div>
        <div
          className="show-less-books"
          className={showLoadMore ? 'hide' : 'show'}
          onClick={() => {
            setBooksToLoad(filteredBooksToShow);
            setShowLoadMore(!showLoadMore);
          }}
        >
          <SecondaryButton buttonText={currentContent?.showLess} />
        </div>
      </div>
      <div className={showRequestForm ? 'request-form-holder show' : 'hide'}>
        <RequestForm enableScroll={enableScroll} showFunction={setRequestForm} showStatus={showRequestForm} />
      </div>
    </div>
  );
}
